import { responsiveStyles } from '@audi/audi-ui-react';
import styled from 'styled-components';
import {
  SPACING_XXL_L,
  SPACING_XXL_M,
  SPACING_XXL_S,
  SPACING_XXL_XL,
  SPACING_XXL_XS,
  SPACING_XXL_XXL,
  SPACING_XXXL_L,
  SPACING_XXXL_M,
  SPACING_XXXL_S,
  SPACING_XXXL_XL,
  SPACING_XXXL_XS,
  SPACING_XXXL_XXL
} from '@audi/audi-ui-design-tokens';
import { ALERT, FOCUS_LARGE, FOCUS_SMALL, FULLSCREEN, LayerTypes } from './globals';

const paddingShader = {
  [ALERT]: {
    xxl: `${SPACING_XXXL_XXL}px 0`, // XXXL_XXL spacing top + bottom,
    xl: `${SPACING_XXXL_XL}px 0`, // XXXL_XL spacing top + bottom,
    l: `${SPACING_XXXL_L}px 0`, // XXXL_XL spacing top + bottom,
    m: `${SPACING_XXXL_M}px 0`, // XXXL_XL spacing top + bottom,
    s: `${SPACING_XXXL_S}px 0 0 0`, // XXXL_S spacing top,
    xs: `${SPACING_XXXL_XS}px 0 0 0` // XXXL_XS spacing top
  },
  [FOCUS_LARGE]: {
    xs: '0'
  },
  [FOCUS_SMALL]: {
    xxl: `${SPACING_XXL_XXL}px 0`, // XXL_XXL spacing top + bottom,
    xl: `${SPACING_XXL_XL}px 0`, // XXL_XXL spacing top + bottom,
    l: `${SPACING_XXL_L}px 0`, // XXL_XXL spacing top + bottom,
    m: `${SPACING_XXL_M}px 0`, // XXL_XXL spacing top + bottom,
    s: `${SPACING_XXL_S}px 0 0 0`, // XXL_XXL spacing top,
    xs: `${SPACING_XXL_XS}px 0 0 0` // XXL_XXL spacing top
  },
  [FULLSCREEN]: {
    xs: '0'
  }
};

const justifyContent = {
  m: 'center',
  xs: 'flex-end'
};

interface IOneLayerOuter {
  type: LayerTypes;
  zIndex?: number;
  invisible: boolean;
}

const OneLayerOuter = styled.div<IOneLayerOuter>`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  opacity: ${({ invisible }) => (invisible ? '0' : '1')};

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${({ zIndex }): string => (zIndex ? `z-index: ${zIndex};` : '')};

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${({ type, theme }): string =>
    paddingShader[type] && responsiveStyles({ padding: paddingShader[type] }, theme.breakpoints)}

  ${({ theme }): string =>
    responsiveStyles({ 'justify-content': justifyContent }, theme.breakpoints)}
`;

export default OneLayerOuter;
