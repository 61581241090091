import React, { useContext, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { LayerDescriptionV2 } from '@volkswagen-onehub/layer-manager';
import { COLOR_BACKGROUND_BACKDROP_STRONG_LIGHT } from '@audi/audi-ui-design-tokens';
import { oneLayerTimingsConfig } from './globals';
import { AppContext } from '../context/app';

interface ShaderProps {
  zIndex?: number;
  layers?: LayerDescriptionV2<unknown>[];
  featureAppId: string;
}

const openKeyFramesShader = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const ShaderStyled = styled.div<ShaderProps>`
  animation-duration: ${oneLayerTimingsConfig.L}ms;
  animation-name: ${openKeyFramesShader};
  animation-timing-function: ${({ theme }): string => theme.easing};
  background-color: ${COLOR_BACKGROUND_BACKDROP_STRONG_LIGHT};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  ${({ zIndex }): string => `z-index: ${zIndex};`};
`;

const Shader: React.FC<ShaderProps> = (props) => {
  const { layers, featureAppId } = props;
  const { historyService } = useContext(AppContext);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    historyService.rootHistory.replace(location.href, { id: featureAppId });
    const hasDeepLink =
      historyService.rootHistory?.location?.hash?.includes('#one-layer=') ||
      historyService.rootHistory?.location?.hash?.includes('#oneLayer=');
    return (): void => {
      if (
        // @ts-expect-error the id is not typed
        historyService.rootHistory?.location?.state?.id &&
        hasDeepLink
      ) {
        // eslint-disable-next-line no-restricted-globals
        historyService.rootHistory.back();
      }
    };
  }, []);

  useEffect(() => {
    const closeAll = (): void => {
      let i: number;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for (i = layers.length - 1; i > -1; i -= 1) {
        if (layers) {
          layers[i]?.layer?.close();
        }
      }
    };
    /* istanbul ignore next */
    window?.addEventListener('popstate', closeAll, false);
    return (): void => {
      /* istanbul ignore next */
      window?.removeEventListener('popstate', closeAll, false);
    };
  }, [layers]);

  return <ShaderStyled {...props} />;
};

export default Shader;
