/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { audiDarkTheme, ButtonGroup, Button } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { LayerTypes } from './globals';

const { xl: breakpointXL } = audiDarkTheme.breakpoints;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(${({ theme }) => theme.colors.background.level['1']});
  z-index: 100;
  @media (min-width: ${breakpointXL}px) {
    background-color: unset;
  }
`;

type Icon = {
  icon: string;
};

interface IClose {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose: (e: any) => void;
  type?: LayerTypes;
  icon?: Icon;
}

const DefaultCloseButton: React.FC<IClose> = (props) => {
  const { onClose } = props;
  return (
    <Button
      aria-label="close button"
      data-testid="one-layer-close"
      onClick={onClose}
      type="button"
      name="closebutton"
      variant="icon-tertiary"
      icon="cancel"
    />
  );
};

const Close: React.FC<IClose> = (props) => {
  return (
    <CloseButtonWrapper>
      <ButtonGroup variant="text-buttons">
        <DefaultCloseButton {...props} />
      </ButtonGroup>
    </CloseButtonWrapper>
  );
};

export default Close;
