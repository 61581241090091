export const FOCUS_LARGE = 'FOCUS_LARGE';
export const FOCUS_SMALL = 'FOCUS_SMALL';
export const ALERT = 'ALERT';
export const FOCUS = 'FOCUS'; // typeof LayerManager which does not get exported
export const FULLSCREEN = 'FULLSCREEN';
export type LayerTypes = typeof ALERT | typeof FOCUS_LARGE | typeof FOCUS_SMALL | typeof FULLSCREEN;
export const oneLayerTimingsConfig = {
  L: 240,
  M: 180,
  XL: 420
};
