import { responsiveStyles } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { ALERT, FOCUS_LARGE, FOCUS_SMALL, FULLSCREEN, LayerTypes } from './globals';

const stylesContent = {
  [ALERT]: {
    m: '32px 48px 48px 48px',
    s: '32px 28px 28px 28px',
    xs: '32px 16px 16px 16px'
  },
  [FOCUS_LARGE]: {
    l: '48px 60px 60px 60px',
    m: '48px 48px 48px 48px',
    s: '48px 28px 28px 28px',
    xs: '48px 16px 16px 16px'
  },
  [FOCUS_SMALL]: {
    l: '48px 60px 60px 60px',
    m: '48px 48px 48px 48px',
    s: '48px 28px 28px 28px',
    xs: '48px 16px 16px 16px'
  },
  [FULLSCREEN]: {
    xs: '0px 0px 0px 0px'
  }
};

const OneLayerContent = styled.div<{ type: LayerTypes; hasPadding?: boolean }>`
  background-color: var(${({ theme }) => theme.colors.background.level['1']});

  // default display padding true
  ${({ type, hasPadding = true, theme }): string =>
    stylesContent[type] &&
    responsiveStyles({ padding: hasPadding ? stylesContent[type] : '0' }, theme.breakpoints)}
`;

export default OneLayerContent;
