/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo } from 'react';
import {
  DisclaimerLayerDescriptionV2,
  FocusLayerSizeV2,
  LayerDescriptionV2
} from '@volkswagen-onehub/layer-manager';
import { Loader } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { Spawn } from '@oneaudi/falcon-tools';
import { ALERT, FOCUS, FOCUS_LARGE, FOCUS_SMALL, FULLSCREEN } from './globals';
import { OneLayer } from './one-layer';
import { clearOneLayerQuery } from './util';
import { AppContext } from '../context/app';
import { filterUrl } from '../utils/LayerContentHTML';

const LoadingWrapper = styled.div`
  text-align: center;
  div {
    color: var(${({ theme }): string => theme.colors.ui.primary});
  }
`;
const loader = (
  <LoadingWrapper>
    <Loader inverted size="small" monochrome spaceStackStart="xl" label="Loading" />
  </LoadingWrapper>
);
interface IOneLayerManager {
  layerDescription: LayerDescriptionV2 | DisclaimerLayerDescriptionV2;

  active: boolean;
  zIndexAlert: number;
  zIndex?: number;
  state?: unknown;
  layerLength: number;
  setStartHideAnimation: (state: boolean) => void;
  startHideAnimation: boolean;
  scrollOffset?: number;
  setScrollOffset: (id: string, n: number) => void;
  layerHistory: number[];
  index: number;
}

const OneLayerManager: React.FC<IOneLayerManager> = (props) => {
  const {
    layerDescription,
    active,
    zIndexAlert,
    zIndex,
    layerLength,
    setStartHideAnimation,
    startHideAnimation,
    setScrollOffset,
    layerHistory,
    scrollOffset,
    index
  } = props;
  // @ts-ignore
  const { close, options, id, state } = layerDescription.layer;
  const { historyService, isHeadless, contentApiConfig } = React.useContext(AppContext);

  const userCloseable = options?.userCloseable !== false;

  const type = useMemo(() => {
    if (layerDescription.type === ALERT) {
      return ALERT;
    }
    if (layerDescription.type === FULLSCREEN) {
      return FULLSCREEN;
    }
    if (layerDescription.type === FOCUS) {
      if (layerDescription.layer.options.size === FocusLayerSizeV2.A) {
        return FOCUS_LARGE;
      }
      return FOCUS_SMALL;
    }
    return undefined;
  }, [layerDescription]);

  const showCloseButton = useMemo(() => {
    if (type === ALERT) {
      return false;
    }
    return options.userCloseable;
  }, [options, type]);

  useEffect(() => {
    if (startHideAnimation && layerLength === 1) {
      // CASE: close one layer immediately
      close();
      setStartHideAnimation(false);
    }
  }, [close, layerLength, setStartHideAnimation, startHideAnimation]);

  const lastHistoryElement = layerHistory[layerHistory.length - 1];

  const showAnimation = useMemo(() => {
    return (
      layerLength > 1 &&
      ((startHideAnimation && !active) || (!startHideAnimation && active)) &&
      lastHistoryElement - index < 3
    );
  }, [active, layerLength, startHideAnimation]);

  const invisible = useMemo(() => {
    return layerLength > 1 && lastHistoryElement - index > 0 && !showAnimation;
  }, [lastHistoryElement, index, layerLength, showAnimation]);

  const hideAnimation = useMemo(
    () =>
      layerLength > 1 &&
      ((startHideAnimation && active) || (!startHideAnimation && !active)) &&
      lastHistoryElement - index < 3,
    [active, layerLength, startHideAnimation]
  );

  const openAnimation = useMemo(() => {
    const isOpen =
      layerLength === 1 &&
      (layerHistory.length === 1 || (lastHistoryElement === 1 && layerHistory.length === 2));

    return isOpen && !showAnimation && !hideAnimation;
  }, [hideAnimation, showAnimation, layerLength, layerHistory, lastHistoryElement]);

  useEffect(() => {
    const listener = (e: KeyboardEvent): void => {
      if ((!userCloseable && userCloseable !== undefined) || type === ALERT) {
        return;
      }

      if (active && e.key === 'Escape') {
        e.stopPropagation();
        clearOneLayerQuery(layerLength, historyService);
        setStartHideAnimation(true);
      }
    };
    /* istanbul ignore next */
    document?.addEventListener('keydown', listener, false);
    return (): void => {
      /* istanbul ignore next */
      document?.removeEventListener('keydown', listener, false);
    };
  }, [active, setStartHideAnimation]);

  // Only re-render the layer content if the layer itself is changed or the state is changed
  const layerContent = useMemo(
    () => layerDescription.layer.render(),
    // @ts-ignore
    [layerDescription.layer, layerDescription.layer.state]
  );
  let url = '';
  // @ts-ignore
  // eslint-disable-next-line no-unsafe-optional-chaining
  url = layerContent?.props.url;
  // @ts-ignore
  const enableDeepLinking = layerContent?.props?.enableDeepLinking;
  useEffect(() => {
    if (url) {
      const filteredUrl = filterUrl(url);
      if (enableDeepLinking) {
        const newParams = {
          oneLayer: filteredUrl
        };
        historyService.rootHistory.replace(`#one-layer=${filteredUrl}`, newParams);
      }
    }
  }, [url]);

  if (!type) {
    return null;
  }

  return (
    <OneLayer
      active={active}
      close={close}
      hideAnimation={hideAnimation}
      id={id}
      index={index}
      openAnimation={openAnimation}
      scrollOffset={scrollOffset}
      setScrollOffset={setScrollOffset}
      setStartHideAnimation={setStartHideAnimation}
      showAnimation={showAnimation}
      showCloseButton={showCloseButton}
      type={type}
      zIndex={zIndex}
      zIndexAlert={zIndexAlert}
      state={state}
      layerLength={layerLength}
      userCloseable={userCloseable}
      invisible={invisible}
    >
      {isHeadless && url ? (
        <div>
          <Spawn
            featureApps={url.trim()}
            idPrefix="test-id"
            contentApiConfig={contentApiConfig}
            loader={loader}
          />
        </div>
      ) : (
        layerContent
      )}
    </OneLayer>
  );
};

export default OneLayerManager;
